@import "normalize.css";
@import "layout.css";
@import "panel-headers.css";
@import "dialog.css";
@import "tabbar.css";
@import "buttonbar.css";
@import "xircles-icons.css";
@import "switch.css";
@import "footer.css";
layer-compose-bar layer-menu-button span {
  font-size: 28px;
}
body layer-compose-bar {
  display: block;
}
body layer-compose-bar .layer-button-panel button.selected {
  color: #E065A4;
}
body layer-compose-bar textarea {
  font-size: 17px;
  border-radius: 22px;
  box-sizing: border-box;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-right: 34px;
  padding-left: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
body layer-compose-bar textarea::-webkit-scrollbar {
  display: none;
}
body layer-compose-bar .layer-compose-edit-panel {
  display: none;
  width: 100%;
  min-height: 34px;
  border: 1px solid #E065A4;
  border-radius: 22px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
body layer-compose-bar .layer-replaceable-inner {
  display: none !important;
}
body layer-compose-bar .hidden-resizer {
  padding: 0 34px 0 16px;
  box-sizing: border-box;
  max-height: 50px;
  font-size: 17px;
}
body layer-compose-bar .hidden-lineheighter {
  padding: 0 34px 0 16px;
  box-sizing: border-box;
  font-size: 17px;
}
body layer-identity-list layer-identity-item {
  height: 39px;
  border-bottom: 1px solid rgba(110, 116, 143, 0.2);
  padding-top: 12px;
}
body layer-identity-list layer-identity-item.layer-size-tiny .layer-identity-name {
  font-size: 16px !important;
}
layer-identity-list layer-identity-item .layer-list-item input[type=checkbox] {
  display: none;
}
.info-panel {
  text-align: center;
  padding-top: 40px;
}
.info-panel img {
  margin-bottom: 40px;
}
.info-panel div {
  width: 70%;
  display: inline-block;
  font-size: 14px;
  color: rgba(110, 116, 143, 0.6);
}
.welcome-panel .title {
  font-size: 20px;
  text-align: center;
  margin-top: 14px;
}
.welcome-panel .sub-title {
  color: rgba(73, 77, 91, 0.5);
}
.brand {
  text-align: center;
  margin-top: 43px;
}
.xircles .xircles-checkbox-message-view {
  border: none;
  text-align: center;
  max-width: none !important;
}
.xircles .xircles-checkbox-message-view button {
  border: 1px solid #E065A4;
  border-radius: 19.5px;
  padding: 9px 18px;
  min-height: 39px;
  color: hsl(329.26829268, 44%, 58%);
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
}
.xircles .xircles-checkbox-message-view .layer-choice-message-view-choices {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
}
.xircles .xircles-checkbox-message-view .layer-choice-message-view-choices > div {
  padding: 12px 12px 10px 14px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #f0f1f3;
  width: 60%;
  margin: auto;
  min-width: 320px;
}
.xircles .xircles-doccheck-message-view {
  border: none;
  text-align: right;
}
.xircles .xircles-doccheck-message-view button {
  border: 1px solid #E065A4;
  border-radius: 19.5px;
  padding: 9px 18px;
  min-height: 39px;
  color: hsl(329.26829268, 44%, 58%);
  font-size: 1rem;
  cursor: pointer;
}
.xircles .layer-choice-message-view {
  border: none;
  text-align: right;
}
.xircles .layer-choice-message-view button {
  border: 1px solid #E065A4;
  border-radius: 19.5px;
  padding: 9px 18px;
  text-align: left;
  min-height: 39px;
  color: hsl(329.26829268, 44%, 58%);
  font-size: 1rem;
  cursor: pointer;
}
.xircles .layer-choice-message-view-choices {
  display: inline-flex;
}
.xircles .layer-choice-message-view-choices.xircles-choice-list-landscape {
  display: block;
}
.xircles .layer-choice-message-view-choices.xircles-choice-list-landscape > layer-action-button {
  display: inline-block;
  margin-left: 10px;
}
.xircles layer-action-button {
  display: block;
  margin-top: 5px;
  text-align: right;
  position: relative;
}
.xircles layer-action-button button {
  font-weight: normal;
  line-height: normal;
  text-align: right;
  transition: all 100ms ease-in-out;
}
.xircles layer-action-button.xircles-icons:before {
  font-size: 24px;
  color: #E065A4;
  position: absolute;
  top: 12px;
  right: 0;
}
.xircles layer-url-button button {
  font-weight: normal;
  line-height: normal;
  text-align: left;
  transition: all 100ms ease-in-out;
}
.xircles .layer-message-item-layer-choice-message-view .layer-message-header {
  xdisplay: none;
}
.xircles .layer-message-item-layer-choice-message-view .layer-list-item {
  xdisplay: none;
}
.xircles layer-message-viewer,
.xircles layer-message-viewer.layer-root-viewer.layer-card-width-flex-width.layer-choice-message-view,
.xircles layer-message-viewer.layer-root-viewer.layer-card-width-flex-width.xircles-disclaimer-message-view {
  max-width: 100%;
  width: 100%;
}
.xircles layer-message-viewer.layer-root-viewer.layer-card-width-flex-width.xircles-simple-answer-message-view,
.xircles layer-message-viewer.layer-root-viewer.layer-card-width-any-width.xircles-simple-answer-message-view {
  max-width: 100% !important;
  width: 100% !important;
  padding: 5px 10px 11px 10px !important;
}
.xircles xircles-checkbox-message-view .layer-choice-message-view-label {
  background-color: rgba(0, 0, 0, 0);
  color: #ccced3;
  font-size: 0.7rem;
  padding: 0 8px;
}
.xircles xircles-checkbox-message-view button.xircles-icons {
  color: #E065A4;
  font-family: Roboto !important;
  font-size: 16px;
  position: relative;
}
.xircles xircles-checkbox-message-view button.xircles-icons:hover {
  cursor: pointer;
}
.xircles xircles-checkbox-message-view button.xircles-icons:before {
  font-family: 'xircles-icons' !important;
  font-size: 24px;
  position: absolute;
  top: 12px;
  right: 0;
}
.xircles xircles-checkbox-message-view button.icon-send {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}
.xircles xircles-checkbox-message-view button.icon-send:before {
  color: #E065A4;
}
.xircles xircles-checkbox-message-view button.icon-up {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}
.xircles xircles-checkbox-message-view .layer-action-button-selected button {
  color: #ffffff;
}
.xircles xircles-checkbox-message-view .layer-action-button-selected button.xircles-icons:before {
  color: #ffffff;
  content: "\E902";
}
.xircles xircles-checkbox-message-view .layer-button.xircles-icons {
  padding-left: 40px;
}
.xircles xircles-checkbox-message-view .layer-button.xircles-icons:before {
  top: 6px;
  left: 8px;
}
.xircles xircles-doccheck-message-view .layer-choice-message-view-label {
  background-color: rgba(0, 0, 0, 0);
  color: #ccced3;
  font-size: 0.7rem;
  padding: 0 8px;
}
.xircles xircles-doccheck-message-view button.xircles-icons {
  color: #E065A4;
  font-family: Roboto !important;
  font-size: 16px;
  position: relative;
}
.xircles xircles-doccheck-message-view button.xircles-icons:hover {
  cursor: pointer;
}
.xircles xircles-doccheck-message-view button.xircles-icons:before {
  font-family: 'xircles-icons' !important;
  font-size: 24px;
  position: absolute;
  top: 12px;
  right: 0;
}
.xircles xircles-doccheck-message-view button.icon-send {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}
.xircles xircles-doccheck-message-view button.icon-send:before {
  color: #E065A4;
}
.xircles xircles-doccheck-message-view button.icon-up {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}
.xircles xircles-doccheck-message-view .layer-action-button-selected button {
  color: #ffffff;
}
.xircles xircles-doccheck-message-view .layer-button.xircles-icons {
  padding-left: 40px;
}
.xircles xircles-doccheck-message-view .layer-button.xircles-icons:before {
  top: 6px;
  left: 8px;
}
.xircles layer-choice-message-view .layer-choice-message-view-label {
  background-color: rgba(0, 0, 0, 0);
  color: #ccced3;
  font-size: 0.7rem;
  padding: 0 8px;
}
.xircles layer-choice-message-view button.xircles-icons {
  color: #E065A4;
  font-family: Roboto !important;
  font-size: 16px;
  position: relative;
}
.xircles layer-choice-message-view button.xircles-icons:hover {
  cursor: pointer;
}
.xircles layer-choice-message-view button.xircles-icons:before {
  font-family: 'xircles-icons' !important;
  font-size: 24px;
  position: absolute;
  top: 12px;
  right: 0;
}
.xircles layer-choice-message-view button.icon-send {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}
.xircles layer-choice-message-view button.icon-send:before {
  color: #E065A4;
}
.xircles layer-choice-message-view button.icon-up {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
}
.xircles layer-choice-message-view .layer-action-button-selected button {
  color: #ffffff;
}
.xircles layer-choice-message-view .layer-action-button-selected button.xircles-icons:before {
  color: #ffffff;
  content: "\E902";
}
.xircles layer-choice-message-view .layer-button.xircles-icons {
  padding-left: 40px;
}
.xircles layer-choice-message-view .layer-button.xircles-icons:before {
  top: 6px;
  left: 8px;
}
.xircles .layer-message-item-layer-text-message-view button.xircles-icons {
  color: #E065A4;
  font-family: Roboto !important;
  font-size: 16px;
  position: relative;
}
.xircles .layer-message-item-layer-text-message-view button.xircles-icons:hover {
  cursor: pointer;
}
.xircles .layer-message-item-layer-text-message-view button.xircles-icons:before {
  font-family: 'xircles-icons' !important;
  font-size: 24px;
  position: absolute;
  top: 12px;
  right: 0;
}
.xircles .layer-message-item-layer-text-message-view button.icon-up {
  border: none;
  color: #ccced3;
  padding-top: 11.5px;
  padding-right: 28px;
  padding-left: 0;
  margin-left: 15px;
  position: relative;
  display: block;
  float: right;
  margin-right: 20px;
}
.xircles layer-titled-message-view-container .layer-card-title-bar {
  display: none;
}
.xircles layer-compose-bar {
  box-sizing: border-box;
  display: block !important;
  background: white;
  border-top: none;
  width: 100%;
  max-width: 650px;
  min-height: 62px;
  margin: auto;
  padding-left: 35px;
  z-index: 11;
  position: relative;
  opacity: 1;
  transition: height 400ms 0ms ease-in-out, min-height 400ms 0ms ease-in-out, top 400ms 0ms ease-in-out, opacity 400ms 0ms ease-in-out;
}
@media only screen and (max-width: 768px) {
  .xircles layer-compose-bar {
    padding-right: 10px;
  }
}
.xircles layer-compose-bar.xircles-hide {
  top: 93px;
  height: 0;
  min-height: 0;
  opacity: 0;
  transition-delay: 0ms;
}
.xircles layer-compose-bar.layer-compose-bar-one-line-of-text .layer-compose-edit-panel {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.xircles layer-compose-bar.layer-compose-bar-one-line-of-text textarea::placeholder {
  color: #ccced3;
}
.xircles layer-compose-bar.layer-is-empty layer-send-button {
  color: #ccced3;
}
.xircles layer-message-viewer.layer-link-message-view {
  border: 0;
  border-radius: 0;
}
.xircles layer-message-viewer.layer-link-message-view layer-standard-message-view-container {
  border: 2px solid #494d5b;
  background-color: #494d5b;
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  background-position: 9px 9px;
  background-repeat: no-repeat;
  background-size: 34px;
}
.xircles layer-link-message-view .layer-link-message-view-image {
  height: 146px;
}
.xircles layer-message-viewer.layer-card-width-flex-width > layer-standard-message-view-container:not(.layer-card-no-metadata) > .layer-card-body-outer {
  position: absolute;
  bottom: 0;
  background: #494d5b;
  color: #ffffff;
  margin: 0;
}
.xircles layer-message-viewer.layer-card-width-full-width > layer-standard-message-view-container:not(.layer-card-no-metadata) > .layer-card-body-outer {
  position: absolute;
  bottom: 0;
  background: #494d5b;
  color: #ffffff;
  margin: 0;
}
.xircles layer-message-viewer.xircles-text-link-message-view > layer-standard-message-view-container:not(.layer-card-no-metadata) > .layer-card-body-outer {
  display: none;
}
.xircles .xircles-study-link layer-message-viewer.layer-link-message-view layer-standard-message-view-container {
  background-image: url(https://www.xircles.com/app/images/study@2x.png);
}
.xircles .xircles-trial-link layer-message-viewer.layer-link-message-view layer-standard-message-view-container {
  background-image: url(https://www.xircles.com/app/images/trial@2x.png);
}
.xircles .xircles-information-link layer-message-viewer.layer-link-message-view layer-standard-message-view-container {
  background-image: url(https://www.xircles.com/app/images/information@2x.png);
  background-size: 24px;
}
.xircles .layer-standard-message-view-container .layer-standard-card-container-title p.layer-line-wrapping-paragraphs {
  color: #aaacb2;
  font-size: 16px;
  line-height: normal;
  font-weight: 300;
}
.xircles .layer-standard-message-view-container .layer-standard-card-container-description p.layer-line-wrapping-paragraphs {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;
}
.xircles layer-list-item-container .layer-standard-card-container-title p.layer-line-wrapping-paragraphs {
  color: #aaacb2;
  font-size: 16px;
  line-height: normal;
  font-weight: 300;
}
.xircles layer-list-item-container .layer-standard-card-container-description p.layer-line-wrapping-paragraphs {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;
}
xircles-message-view-container {
  padding: 8px;
}
.xircles-xircle-overview-message-view {
  max-width: 350px;
}
xircles-xircle-overview-message-view > .xircles-xircle-overview-message-image {
  width: 28%;
  margin-left: 6px;
  margin-right: 6px;
  -ms-flex-item-align: center;
  align-self: center;
  background-size: cover;
  background-position: 50%;
  height: 0px;
  padding-bottom: 22%;
  background-size: contain;
  background-repeat: no-repeat;
}
xircles-xircle-overview-message-view > .xircles-xircle-overview-message-view-right {
  width: 60%;
}
xircles-xircle-overview-message-view > .xircles-xircle-overview-message-view-right > div {
  border-left: 1px solid rgba(110, 116, 143, 0.4);
  margin-top: 5px;
  padding-top: 3px;
  margin-bottom: 5px;
  padding-bottom: 3px;
  padding-left: 5px;
  xposition: absolute;
  top: 0;
  bottom: 0;
  min-height: 61px;
}
xircles-xircle-overview-message-view > .xircles-xircle-overview-message-view-right > div > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 7px;
  margin-bottom: 1px;
}
xircles-xircle-overview-message-view > .xircles-xircle-overview-message-link > a {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #E065A4;
}
xircles-result-message-view {
  color: #494d5b;
  background: white;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.17;
  margin-right: 20px;
}
xircles-result-message-view .hide-header,
xircles-result-message-view .hide-snippet {
  display: none;
}
xircles-result-message-view .breadcrumbs-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
xircles-result-message-view button.show-details {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  height: 19px;
  width: 19px;
  padding: 0;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
xircles-result-message-view button.show-details i {
  position: unset;
  padding: 0;
  font-size: 15px;
}
xircles-result-message-view button.show-details_toogle {
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
xircles-result-message-view .breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0 0 0 7px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
xircles-result-message-view .breadcrumbs li + li:before {
  padding: 0 5px 0 5px;
  color: #494d5b;
  content: '\00bb';
}
xircles-result-message-view .breadcrumbs li {
  cursor: pointer;
  display: inline;
  color: #494d5b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8em;
  letter-spacing: normal;
}
xircles-result-message-view .breadcrumbs li h1,
xircles-result-message-view .breadcrumbs li h2 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}
xircles-result-message-view > .header {
  border-bottom: 1px solid #f0f1f3;
  padding: 10px 70px 11px 0;
  position: relative;
  cursor: pointer;
}
xircles-result-message-view > .header span p {
  margin: 0;
}
xircles-result-message-view > .header em {
  font-style: normal;
}
xircles-result-message-view > .header em.hlt1,
xircles-result-message-view > .header em.hlt2,
xircles-result-message-view > .header em.hlt3,
xircles-result-message-view > .header em.hlt4,
xircles-result-message-view > .header em.hlt5,
xircles-result-message-view > .header em.hlt6,
xircles-result-message-view > .header em.hlt7,
xircles-result-message-view > .header em.hlt8,
xircles-result-message-view > .header em.hlt9,
xircles-result-message-view > .header em.hlt10 {
  font-style: italic;
}
xircles-result-message-view > .header h1,
xircles-result-message-view > .header h2,
xircles-result-message-view > .header h3 {
  display: inline;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  word-break: break-word;
  line-height: 1.3rem;
}
xircles-result-message-view > .header h3 {
  display: inline-block;
}
xircles-result-message-view > .header h1 + sup,
xircles-result-message-view > .header h2 + sup {
  top: -0.4em;
}
xircles-result-message-view > .snippet {
  cursor: pointer;
}
xircles-result-message-view > .snippet h2,
xircles-result-message-view > .snippet h3,
xircles-result-message-view > .snippet h4,
xircles-result-message-view > .snippet p,
xircles-result-message-view > .snippet b {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}
xircles-result-message-view > .snippet h2,
xircles-result-message-view > .snippet h3,
xircles-result-message-view > .snippet h4,
xircles-result-message-view > .snippet b {
  color: #494d5b;
}
xircles-result-message-view > .snippet h3 {
  display: inline-block;
}
xircles-result-message-view > .snippet h3 + h3 {
  margin-left: 4px;
}
xircles-result-message-view > .snippet > br,
xircles-result-message-view > .snippet b + br,
xircles-result-message-view > .snippet b > br,
xircles-result-message-view > .snippet p > br:first-child,
xircles-result-message-view > .snippet p > br:nth-child(2) {
  display: none;
}
xircles-result-message-view i {
  color: #E065A4;
  border: none;
  padding: 0 0 6px;
}
xircles-blocks-result-message-view .block-result-message em {
  background-color: hsl(329.26829268, 67%, 92%);
  font-style: normal;
  padding: 1px 3px;
}
xircles-blocks-result-message-view .block-result-message__breadcrumbs li:last-of-type > * {
  color: #E065A4;
}
xircles-blocks-result-message-view .block-result-message__breadcrumbs li:not(:first-of-type):before {
  padding: 0 10px 0 0;
  color: #494d5b;
  content: '\00bb';
}
xircles-blocks-result-message-view .block-result-message__breadcrumbs li:not(:nth-child(2)):last-of-type:before {
  content: '|';
}
xircles-blocks-result-message-view .block-result-message__show-more {
  display: inline-block;
  margin-left: 5px;
  color: #E065A4;
  font-weight: bold;
}
xircles-blocks-result-message-view .block-result-message--marked .block-result-message__title,
xircles-blocks-result-message-view .block-result-message--marked .block-result-message__toggle-button i {
  color: #aaacb2;
}
xircles-blocks-result-message-view .block-result-message__collapse-item-label {
  background-color: hsl(329.26829268, 68%, 84%);
}
xircles-blocks-result-message-view .block-result-message__collapse-item-label--selected {
  background-color: #E065A4;
}
xircles-results-message-view xircles-select-drug-view.layer-message-type-view {
  padding: 0 !important;
  border: none !important;
  position: fixed;
  background-color: transparent !important;
  z-index: 12;
  max-width: 700px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 155px;
}
@media screen and (max-width: 767px) {
  xircles-results-message-view xircles-select-drug-view.layer-message-type-view {
    top: 48px;
    max-width: none;
  }
  xircles-results-message-view xircles-select-drug-view.layer-message-type-view .select-drug {
    max-width: 700px;
    border-radius: 0;
  }
}
@media screen and (max-width: 459px) {
  xircles-results-message-view xircles-select-drug-view.layer-message-type-view .select-drug {
    padding-left: 10px;
    padding-right: 10px;
  }
}
xircles-results-message-view xircles-select-drug-view.layer-message-type-view #select-drug i {
  color: #E065A4;
}
@media screen and (max-width: 967px) {
  .long-question xircles-results-message-view xircles-select-drug-view.layer-message-type-view {
    top: 48px;
  }
}
xircles-result-message-view.join-previous-sibling {
  box-shadow: 0px 14px 0px 1px #f0f1f3;
  padding-bottom: 0px !important;
  min-height: 21px !important;
  background-color: #f0f1f3 !important;
  border-radius: 5px !important;
}
xircles-result-message-view.expand-view {
  background-color: #ffffff !important;
  margin-bottom: 1px;
}
xircles-result-message-view.join-view,
xircles-result-message-view.expand-view,
xircles-result-message-view.join-previous-sibling {
  padding: 8px 10px 8px 10px !important;
  border: solid 1px #f0f1f3;
  border-radius: 5px !important;
  box-sizing: border-box !important;
}
xircles-result-message-view.join-view .snippet,
xircles-result-message-view.expand-view .snippet,
xircles-result-message-view.join-previous-sibling .snippet {
  margin-top: 5px;
}
xircles-result-message-view.join-view .snippet p,
xircles-result-message-view.expand-view .snippet p,
xircles-result-message-view.join-previous-sibling .snippet p {
  position: relative;
  color: #494d5b;
}
xircles-result-message-view.join-view .snippet p:after,
xircles-result-message-view.expand-view .snippet p:after,
xircles-result-message-view.join-previous-sibling .snippet p:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 23%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
}
xircles-result-message-view.join-view {
  min-height: 21px !important;
  margin-bottom: 0;
}
xircles-result-message-view.join-view__minimized {
  background-color: #f0f1f3 !important;
  margin-top: 15px;
  box-shadow: unset;
}
xircles-result-message-view.join-view__without-margin {
  margin-top: 0px;
}
xircles-result-message-view.join-previous-sibling.expand-view:hover + xircles-result-message-view.join-view {
  box-shadow: 0px -14px 0px 1px #f0f1f3;
  z-index: 2;
}
xircles-result-message-view.join-previous-sibling.expand-view:hover + xircles-result-message-view.join-view + xircles-result-message-view.join-view {
  box-shadow: 0px -14px 0px 1px #f0f1f3;
  z-index: 1;
}
xircles-result-message-view.join-previous-sibling.expand-view:hover + xircles-result-message-view.join-view + xircles-result-message-view.join-view + xircles-result-message-view.join-view {
  box-shadow: 0px -14px 0px 1px #f0f1f3;
}
xircles-results-message-view .layer-message-type-view {
  padding: 8px 10px 8px 10px !important;
  border: solid 1px #f0f1f3;
  border-radius: 10px !important;
  box-sizing: border-box;
  backface-visibility: hidden;
  background-color: #f0f1f3 !important;
  position: relative;
}
xircles-results-message-view .layer-message-type-view__simple-element {
  background-color: #ffffff !important;
  border: none;
  padding: 6px 10px 5px !important;
}
xircles-results-message-view .layer-message-type-view__simple-element:hover {
  border: none;
  padding: initial;
}
xircles-results-message-view .layer-message-type-view__expanded {
  background-color: #ffffff !important;
}
xircles-results-message-view .layer-message-type-view__expanded:hover {
  z-index: 10;
  box-shadow: 0px 0px 0px 2px #E065A4 inset;
}
xircles-results-message-view .layer-message-type-view__expanded .snippet {
  min-height: 61px;
  margin-top: 5px;
}
xircles-results-message-view .layer-message-type-view__expanded .snippet p {
  position: relative;
  color: #494d5b;
}
xircles-results-message-view .layer-message-type-view__expanded .snippet p:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 23%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
}
xircles-results-message-view .layer-message-type-view__expanded--single-with-margin {
  margin-top: 7px;
}
xircles-results-message-view .layer-message-type-view--offset {
  margin-top: 8px;
}
xircles-results-message-view .layer-message-type-view .button-more,
xircles-results-message-view .layer-message-type-view .button-more-simple {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  color: #E065A4;
  background-color: #ffffff;
  padding: 7px 7px 7px 14px;
}
xircles-results-message-view .layer-message-type-view .button-more i,
xircles-results-message-view .layer-message-type-view .button-more-simple i {
  padding: 0;
  margin-left: 7px;
  font-size: 20px;
}
xircles-results-message-view .layer-message-type-view .button-more__hidden,
xircles-results-message-view .layer-message-type-view .button-more-simple__hidden {
  display: none;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__expanded .button-more-simple {
  display: none;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__expanded .button-more {
  bottom: 18px;
  right: 20px;
  border-radius: 19.5px;
  border: solid 1px #E065A4;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__expanded .button-more__hidden {
  display: none;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__expanded .button-more:active {
  background-color: #E065A4;
  color: #ffffff;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__expanded .button-more:active i {
  color: #ffffff;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__simple-element .button-more {
  display: none;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__simple-element .button-more-simple {
  right: -5px;
  bottom: 0px;
}
xircles-results-message-view .layer-message-type-view.layer-message-type-view__simple-element .button-more-simple span {
  display: none;
}
xircles-results-message-view xircles-result-message-view:first-child {
  margin-top: 0;
}
.layer-conversation-view-width-large .layer-message-item-xircles-related-questions-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-large .layer-message-item-xircles-matched-questions-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-large .layer-message-item-xircles-results-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-medium .layer-message-item-xircles-related-questions-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-medium .layer-message-item-xircles-matched-questions-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-medium .layer-message-item-xircles-results-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-small .layer-message-item-xircles-related-questions-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-small .layer-message-item-xircles-matched-questions-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.layer-conversation-view-width-small .layer-message-item-xircles-results-message-view layer-message-viewer.layer-root-viewer.layer-card-width-any-width {
  max-width: none;
  width: 100%;
  padding: 0 10px;
  border-radius: 0;
}
.play-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 10px;
  right: 10px;
  color: #ffffff;
}
.layer-list-meta + .layer-message-item-xircles-question-message-view {
  display: none;
}
.layer-message-item-xircles-checkbox-message-view .layer-message-header {
  display: none !important;
}
.xircles-show {
  display: inline-block;
}
.xircles-show.layer-message-item-xircles-checkbox-message-view button {
  display: inline-block;
}
/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
@keyframes fade {
  0% {
    background-color: rgba(27, 28, 29, 0.66);
  }
  50% {
    background-color: #1b1c1d;
  }
  100% {
    background-color: rgba(27, 28, 29, 0.66);
  }
}
.react-orientation {
  display: none;
}
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #494d5b;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}
body .mdc-icon-button {
  --mdc-ripple-fg-size: 0 !important;
  outline: 0 !important;
}
body > * {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-tap-highlight-color: transparent;
}
body * {
  font-variant-ligatures: none;
}
body.service-widget {
  background-color: white;
}
a {
  text-decoration: none;
  outline: none;
}
button {
  outline: none;
  border: none;
  background: transparent;
}
b {
  font-size: 20px;
  font-weight: 700;
}
strong {
  font-size: 20px;
  font-weight: 700;
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#root {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background-color: #f0f1f3;
}
.messenger {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.xircles-icons {
  font-size: 24px;
}
.xircles-hide {
  display: none !important;
}
.xircles-hiding {
  animation: opacity-hiding 100ms ease-in-out forwards;
}
.xircles-disabled {
  border-color: #a4a8b2 !important;
  color: #a4a8b2 !important;
  cursor: default !important;
}
.xircles-cover {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.xircles-badge {
  width: 30px;
  text-align: center;
}
.xircles-badge > span {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  border: 2px solid #009b77;
  height: 14px;
  border-radius: 11px;
  min-width: 14px;
  color: #ffffff;
  background: #009b77;
  padding-top: 1px;
  padding-right: 1px;
}
.xircles layer-message-list {
  padding: 0;
}
.xircles layer-message-list .layer-list-item .layer-message-footer {
  padding-left: 10px;
}
.xircles .layer-list-item-separator-parent .layer-list-item-separator-date-inner {
  border: none;
}
.xircles .layer-list-item-separator-parent .layer-list-item-separator-date-inner > layer-date {
  border: none;
}
.xircles layer-message-item-status {
  display: none;
}
.xircles layer-notifier {
  display: none;
}
.layer-message-item {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.layer-message-item:last-child {
  min-height: 32px;
}
.layer-message-item-layer-file-message-view .layer-message-item-main {
  padding-left: 10px;
}
:not(layer-conversation-item) > .layer-list-item {
  margin-bottom: 5px;
}
.layer-message-item-layer-response-message-view {
  display: none;
}
.xircles-typing-indicator {
  display: none;
  background-color: #f0f1f3;
  color: #1b1c1d;
  padding: 5px 10px 8px;
  border-radius: 10px;
  width: 34px;
  margin-left: 32px;
}
.xircles-typing-indicator span {
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #1b1c1d;
  margin-right: 4px;
  border-radius: 4px;
  animation: fade 1.2s 0.2s infinite;
}
.xircles-typing-indicator span:first-child {
  animation: fade 1.2s infinite;
}
.xircles-typing-indicator span:last-child {
  margin-right: 0;
  animation: fade 1.2s 0.2s infinite;
}
.xircles-typing-indicator.layer-typing-occuring {
  display: block;
}
layer-typing-indicator.layer-typing-occuring {
  display: none;
}
.layer-item-filtered {
  display: none;
}
@media all and (orientation: landscape) {
  .react-orientation--landscape {
    display: inherit;
  }
}
@media all and (orientation: portrait) {
  .react-orientation--portrait {
    display: inherit;
  }
}
@media (max-width: 600px) {
  .messenger {
    flex-direction: row;
  }
}
