/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.document-container main .content .smpc-mmi {
  position: relative;
}
.document-container main .content .smpc-mmi h5 > br {
  display: none;
}
.document-container main .content .smpc-mmi h4,
.document-container main .content .smpc-mmi h5,
.document-container main .content .smpc-mmi h6 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 10px;
}
.document-container main .content .smpc-mmi h4 {
  text-decoration: underline;
}
.document-container main .content .smpc-mmi h5 {
  font-style: italic;
  text-decoration: underline;
}
.document-container main .content .smpc-mmi h6 {
  font-style: italic;
}
.document-container main .content .smpc-mmi p {
  margin-top: 0px;
  margin-bottom: 0px;
}
.document-container main .content .smpc-mmi b {
  font-size: 16px;
  display: inline;
}
.document-container main .content .smpc-mmi sub,
.document-container main .content .smpc-mmi sup {
  display: inline !important;
}
.document-container main .content .smpc-mmi br {
  line-height: 200%;
  vertical-align: top;
  height: 10px;
  width: 100%;
  content: ' ';
  display: block;
}
.document-container main .content .smpc-mmi br.colon-br {
  margin-bottom: 0px !important;
}
.document-container main .content .smpc-mmi br + b {
  display: inline;
}
.document-container main .content .smpc-mmi img {
  display: block;
  max-width: 100%;
}
.document-container main .content .smpc-mmi br + img {
  margin: 16px 0;
}
.document-container main .content .smpc-mmi img[width^="0."][height^="0."] {
  display: none;
}
.document-container main .content .smpc-mmi table {
  flex-wrap: wrap;
  overflow-x: auto;
  padding: 10px 0px;
}
.document-container main .content .smpc-mmi table tbody {
  width: 100%;
  display: inline-table;
}
.document-container main .content .smpc-mmi table p {
  font-size: 0.875em;
  line-height: 1.15;
  padding: 0px;
  margin: 0px;
}
.document-container main .content .smpc-mmi table tbody > tr {
  background-color: #ffffff;
  color: #494d5b;
  -webkit-print-color-adjust: exact;
  justify-content: space-between;
}
.document-container main .content .smpc-mmi table tbody > tr td {
  padding: 5px 10px;
  font-size: 0.875em;
  box-sizing: border-box;
}
.document-container main .content .smpc-mmi table tbody > tr:first-child td,
.document-container main .content .smpc-mmi table tbody > tr:first-child th {
  padding: 5px;
  font-size: 0.875em;
  box-sizing: border-box;
}
.document-container main .content .smpc-mmi div.product-metadata-block p > br:not(br:last-child) {
  margin-bottom: 0px !important;
}
.document-container main .content .smpc-mmi div.product-metadata-block p > b {
  font-weight: normal;
}
.document-container main .content .smpc-mmi div.product-metadata-block p > b > br {
  margin-bottom: 0px !important;
}
.document-container main .content .smpc-mmi span.bullet-margin-left {
  margin-left: 15px;
  box-decoration-break: clone;
}
.document-container main .content .smpc-mmi li.dash {
  list-style-type: '— ';
  text-indent: -16.5px;
  padding-left: 20px;
}
.document-container main .content .smpc-mmi li.bullet {
  text-indent: -21px;
  padding-left: 22px;
}
.document-container main .content .smpc-mmi li.second-level-bullet {
  text-indent: -22px;
  padding-left: 42px;
}
.document-container main .content .smpc-mmi li.dash,
.document-container main .content .smpc-mmi li.bullet,
.document-container main .content .smpc-mmi li.second-level-bullet {
  line-height: 22px;
}
