/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.auto-suggest {
  max-width: 640px;
  max-height: 419px;
  background-color: #ffffff;
  border: solid 1px #e9ebef;
  border-radius: 0 0 10px 10px;
  border-top-style: none;
}
@media only screen and (max-width: 767px) {
  .auto-suggest {
    height: 100%;
  }
}
.auto-suggest__example {
  padding: 72px 70px 40px 36px;
  margin: 0;
  list-style: none;
  min-height: 420px;
  box-sizing: border-box;
  color: #ccced3;
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.53px;
  text-align: left;
}
.auto-suggest__example .auto-suggest__question-example {
  margin-top: 10px;
  font-weight: bold;
}
.auto-suggest__example .auto-suggest__ask-question {
  margin-top: 20px;
}
.auto-suggest__example .auto-suggest__doc-check {
  margin-top: 50px;
}
.auto-suggest__example .auto-suggest__doc-check span {
  margin-right: 10px;
}
.auto-suggest__example .auto-suggest__doc-check button {
  border: 1px solid #E065A4;
  border-radius: 19.5px;
  padding: 9px 18px;
  text-align: left;
  min-height: 39px;
  color: hsl(329.26829268, 44%, 58%);
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}
.auto-suggest__search-results {
  list-style: none;
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.53px;
  text-align: left;
  color: #494d5b;
  margin: 0;
  padding: 24px 0 18px 10px;
  overflow-y: scroll;
  max-height: 353px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.auto-suggest__search-results::-webkit-scrollbar {
  width: 0 !important;
}
.auto-suggest__search-results .auto-suggest__search-result {
  margin: 0 10px 0 0;
  cursor: pointer;
}
.auto-suggest__search-results .auto-suggest__search-result em {
  font-weight: bold;
  font-style: normal;
}
.auto-suggest__search-results .auto-suggest__search-result b {
  font-size: 24px;
}
.auto-suggest__search-results .auto-suggest__search-result span {
  vertical-align: middle;
  background-color: #8DD2CF;
  box-sizing: border-box;
  height: 23px;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 3px;
  margin: 0;
}
.auto-suggest__search-results .auto-suggest__search-result.disabled {
  cursor: default;
}
.auto-suggest__search-results .auto-suggest__search-result compound {
  background-color: #dbdbde;
  border-radius: 3px;
  padding: 2px 5px;
}
.auto-suggest__search-results .auto-suggest__search-result drug {
  font-style: normal;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 24px;
  border-radius: 3px;
  padding: 2px 5px;
  background-color: hsl(329.26829268, 67%, 92%);
}
.auto-suggest__search-results .auto-suggest__search-result tagged {
  display: none;
  vertical-align: middle;
  margin-left: 10px;
  padding: 4px 8px 4px 8px;
  background-color: #e6600f;
  color: #ffffff;
  border-radius: 3px;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0;
}
.auto-suggest__search-results .auto-suggest__search-result tagged em {
  font-weight: bold;
}
.auto-suggest__search-results .auto-suggest__search-result placeholder {
  padding: 2px 5px;
  border: dashed 1px #aaacb2;
  border-radius: 4px;
  color: #aaacb2;
}
@media only screen and (max-width: 767px) {
  .auto-suggest__search-results .auto-suggest__search-result {
    font-size: 21px;
    line-height: 24px;
  }
  .auto-suggest__search-results .auto-suggest__search-result b,
  .auto-suggest__search-results .auto-suggest__search-result drug {
    font-size: 21px;
    line-height: 24px;
  }
  .auto-suggest__search-results .auto-suggest__search-result tagged {
    font-size: 12px;
    padding: 3px 6px 3px 6px;
  }
}
@media only screen and (max-width: 375px) {
  .auto-suggest__search-results .auto-suggest__search-result {
    font-size: 16px;
  }
  .auto-suggest__search-results .auto-suggest__search-result b,
  .auto-suggest__search-results .auto-suggest__search-result drug {
    font-size: 16px;
  }
  .auto-suggest__search-results .auto-suggest__search-result tagged {
    font-size: 11px;
    padding: 2px 6px 2px 6px;
  }
}
.auto-suggest__search-results .auto-suggest__search-result:not(:first-of-type) {
  margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  .auto-suggest__search-results {
    max-height: calc(100% - 137px);
  }
}
