/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.product-card {
  border-radius: 10px;
  width: 335px;
  height: 126px;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  margin: 10px 5px 0;
}
@media screen and (max-width: 767px) {
  .product-card {
    margin: 10px 0 0;
    width: 335px;
  }
  .product-card .product-card__name {
    max-width: 278px;
  }
}
@media screen and (max-width: 376px) {
  .product-card {
    width: 100%;
  }
  .product-card .product-card__name {
    max-width: 220px;
  }
}
.product-card .product-card__name {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #494d5b;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 20px 20px 0 20px;
}
.product-card .product-card__name a {
  color: #494d5b;
}
.product-card .product-card__compound_name {
  margin: 10px 20px 0 20px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #a4a8b2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
.product-card .product-card__information {
  margin: 10px 20px 0 20px;
}
.product-card .product-card__information .product-card__manufactures,
.product-card .product-card__information .product-card__technical-informations {
  color: #494d5b;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
}
.product-card .product-card__information .product-card__manufactures + .product-card__technical-informations {
  margin-left: 20px;
}
