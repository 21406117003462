/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.product-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f1f3;
}
.product-page .product-page__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 26px;
  height: unset;
}
.product-page .product-page__header .brand-header__welcome {
  min-height: unset;
}
.product-page .product-page__header .xircles-logo {
  margin: 88px 0 34px 0;
}
@media only screen and (max-width: 767px) {
  .product-page .product-page__header .xircles-logo {
    margin: 77px 0 52px 0;
  }
}
@media only screen and (max-width: 767px) {
  .product-page .product-page__header {
    padding: 0 20px 20px;
  }
  .product-page .product-page__header .product-page__login h6 {
    display: none;
  }
}
@media only screen and (max-width: 680px) {
  .product-page .product-page__header .product-page__welcome {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .product-page .product-page__header {
    height: 100%;
    padding-bottom: 28px;
  }
}
.product-page .brand-header__select-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1022px;
  margin-bottom: 30px;
}
.product-page .brand-header__select-area .brand-header__compound-component {
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #ccced3;
}
.product-page .brand-header__select-area .brand-header__show-drug {
  margin-left: 10px;
  color: #494d5b;
  max-width: 282px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 375px) {
  .product-page .brand-header__select-area .brand-header__show-drug {
    max-width: 252px;
  }
}
.product-page .brand-header__select-area .brand-header__compound-component,
.product-page .brand-header__select-area .brand-header__show-drug {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.53px;
  text-align: left;
}
@media only screen and (max-width: 1034px) {
  .product-page .brand-header__select-area {
    max-width: 670px;
  }
}
@media only screen and (max-width: 580px) {
  .product-page .brand-header__select-area {
    margin-bottom: 25px;
  }
  .product-page .brand-header__select-area .brand-header__compound-component,
  .product-page .brand-header__select-area .brand-header__show-drug {
    font-size: 16px;
  }
}
.product-page .product-page__content {
  background-color: #f0f1f3;
  padding-top: 50px;
}
.product-page .product-overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1022px;
  margin: 50px auto 100px auto;
}
@media screen and (max-width: 767px) {
  .product-page .menu-button {
    top: 10px;
    right: 10px;
  }
  .product-page .auto-suggest {
    position: initial;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .product-page .product-overview {
    width: 678px;
  }
}
@media screen and (max-width: 767px) {
  .product-page .product-overview {
    width: 100% !important;
    max-width: 334px;
    box-sizing: border-box;
  }
}
